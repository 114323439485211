import React from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';

const CaseStudy = () => {
  const { id } = useParams();
  const caseStudyData = getCaseStudyById(id);

  if (!caseStudyData) {
    return null;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="bg-gray-100 py-12 mt-40 px-6 sm:px-12">
      <div className="container mx-auto bg-white rounded-xl p-6 sm:p-12">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-6">{caseStudyData.title}</h1>

        <div className="mb-6">
          <Slider {...settings}>
            {caseStudyData.images.map((imageSrc, index) => (
              <div key={index}>
                <img
                  src={imageSrc}
                  alt={`${caseStudyData.title} image ${index + 1}`}
                  className="w-full h-auto rounded-lg shadow-md mb-6"
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Brief description of Services provided:</h2>
          <div className="text-base text-gray-700">{caseStudyData.details}</div>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6">Challenges faced:</h2>
          <ul className="list-disc pl-5 text-gray-700">
            {caseStudyData.challenges.map((challenge, index) => (
              <li key={index} className="mb-2">{challenge}</li>
            ))}
          </ul>
        </div>

        <div className="flex justify-center mt-8">
          <a
            href="/"
            className="px-6 py-3 bg-custom-blue text-white font-semibold text-lg rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Back to Projects
          </a>
        </div>
      </div>
    </div>
  );
};

const getCaseStudyById = (id) => {
  const caseStudies = [
    {
      id: "1",
      title: "Upgrade of Lagos Water Corporation",
      details: "TraceCorp Solutions Limited was contracted by Lagos Water Corporation to design, develop and deploy a Billing system to digitize the manual billing and payment processes and improve the efficiency of the billing process in Lagos Water Corporation. Lagos Water billing system is a web-based application that can accommodate from 1 (one) to infinity number of concurrent users and logins with Six (6) main modules, which include Billing, Payments, New connection, Meter Management, Mobile App, and a CRM.",
      images: ["/images/osun/9.jpg", "/images/osun/12.jpg", "/images/osun/7.jpg", "/images/osun/18.jpg", "/images/osun/16.jpg"],
      challenges: [
        "Ensuring accurate migration from legacy systems and cleaning up inconsistent data.",
        " Integrating with existing systems and third-party platforms while avoiding compatibility issues",
        "Training staff to use the new system and managing resistance to change.",
        "Ensuring the system can handle increased customer load without performance issues.",
        "Protecting sensitive customer data and meeting regulatory requirements.",
        "Managing diverse expectations and ensuring clear communication with customers.",
        "Customizing the system to meet LWC’s specific needs without vendor lock-in.",
        "Providing ongoing maintenance, troubleshooting, and improvements.",
        "Overcoming staff resistance and streamlining workflows.",
      ],
    },
    {
      id: "2",
      title: "Consultancy Services for Lagos Water Corporation (LWC) E-procurement System",
      details: "TraceCorp Solutions was contracted by Lagos Water Corporation to design, develop and deploy an Electronic Procurement system to digitize the manual procurement process and improve the efficiency of the procurement process in Lagos Water Corporation. EProcurement module for Lagos water comprises of planning, budgeting, bidding and sourcing.",
      images: ["/images/osun/1.jpg", "/images/osun/3.jpg", "/images/osun/4.jpg", "/images/osun/6.jpg", "/images/osun/7.jpg"],
      challenges: [
        "Resistance to adopting a new digital procurement system.",
        "Inconsistent data across procurement processes.",
        "Challenges in training staff to use the new system efficiently."
      ],
    },
    {
      id: "3",
      title: "Long Term Agreement - System strengthening services for eleven (11) Town Water Utilities in secondary towns in Ethiopia under UNICEF Ethiopia.",
      details: "2ML Consulting Limited sub-contracted Tracecorp Solutions under this project to provide a comprehensive suite of services aimed at enhancing the operational and financial systems of water utilities. These services included the procurement and installation of ICT equipment, as well as the gathering, analysis, and re-engineering of business processes related to billing and accounting. A key aspect of the project was the design and implementation of a Billing System that seamlessly integrates with metering data to generate accurate invoices based on usage, tariffs, and service fees. The system supports various payment methods such as online, in-person, and automatic payments, while efficiently managing accounts receivable and handling billing adjustments, reconciliations, and customer inquiries. This automation ensures timely and accurate billing, improving operational efficiency and customer satisfaction. Additionally, Tracecorp Solutions developed a robust Accounting System to manage financial transactions, including revenue collection, expenditure tracking, and asset management. The system facilitates billing processes, accounts receivable and payable, while ensuring accurate financial records and compliance with regulations. By tracking the costs associated with water production, distribution, and maintenance, the system provides valuable data for budgeting and financial planning. Integration with metering and payment systems further enhances accuracy, transparency, and accountability in financial reporting. To ensure effective use of the systems, user and administrative manuals were developed, comprehensive training was provided to users, and ongoing support and maintenance services were offered to guarantee the continued success and functionality of the project.",
      images: ["/images/ethio5.jpg", "/images/ethiopia.jpg", "/images/ethio1.jpg", "/images/ethio2.jpg", "/images/ethio3.jpg"],
      challenges: [
        "Poor connectivity in rural areas.",
        "Training users in a new system without disrupting daily operations.",
        "Difficulty in customizing the system to meet local government requirements.",
        "Seamless integration of the new Billing and Accounting Systems with existing infrastructure.",
        "Technical complexities related to data compatibility and system synchronization.",
        "Gathering and analyzing requirements for the re-engineering of billing and accounting processes.",
        "Handling billing adjustments, reconciliations, and customer inquiries efficiently.",
        "Ensuring system accuracy and reliability throughout the implementation."
      ],
    },
    {
      id: "4",
      title: "Software Installation at Urban Water Corporation in Bor town, South Sudan.",
      details: "2ML Consulting Limited sub-contracted Tracecorp Solutions to provide essential services for the Urban Water Corporation in Bor Town, South Sudan. These included gathering and re-engineering business processes for billing, accounting, asset management, and customer relationship management. Tracecorp designed and implemented a Billing System, a Customer Relationship Management (CRM) system, and a Financial Management Information System (FMIS) to manage financial transactions and enhance operational efficiency. They also developed an Asset Management System to optimize the utility’s resources. The project included user training, the creation of manuals, and ongoing support and maintenance to ensure smooth system operation.",
      images: ["/images/25.jpg", "/images/26.jpg", "/images/bor2.jpg", "/images/bor3.jpg"],
      challenges: [
        "Limited internet access and technological infrastructure in the region.",
        "Cultural resistance to adopting new digital systems.",
        "Lack of local technical support for the system post-deployment."
      ],
    },
  ];

  return caseStudies.find((study) => study.id === id);
};

export default CaseStudy;
