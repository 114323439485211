import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const AllProjects = () => {
    const navigate = useNavigate();

    const handleClick = (index) => {
        navigate(`/case-study/${index + 1}`);
    };

    const projects = [
        {
            id: 1,
            title: "Upgrade of Lagos Water Corporation",
            description: "Upgrade of Lagos Water Corporation customer information, Billing and Collection System", description: "TraceCorp Solutions Limited was contracted by Lagos Water Corporation to design, develop and deploy a Billing system to digitize the manual billing and payment processes and improve the efficiency of the billing process in Lagos Water Corporation.",
            imageSrc: "../../../images/osun/14.jpg",
        },
        {
            id: 2,
            title: "Consultancy Services for Electronic Procurement System",
            description: "TraceCorp Solutions conducted a thorough analysis of LWC's procurement processes, improving transparency and efficiency.",
            imageSrc: "../../../images/osun/1.jpg",
        },
        {
            id: 3,
            title: "Long Term Agreement - System strengthening services for eleven (11) Town Water Utilities in secondary towns in Ethiopia under UNICEF Ethiopia.",
            description: "2ML Consulting Limited sub-contracted Tracecorp Solutions under this project to provide several key services. These included procuring and installing ICT equipment, gathering requirements, and re-engineering the billing and accounting business processes. Tracecorp also designed and implemented an integrated Billing System that generates accurate invoices based on usage, tariffs, and service fees, supporting various payment methods and managing accounts receivable. Additionally, they developed an Accounting System to track financial transactions, support revenue collection, and ensure compliance. The project also involved the creation of user and administrative manuals, user training, and ongoing support and maintenance services.",
            imageSrc: "../../../images/ethio1.jpg",
        },
        {
            id: 4,
            title: "Software Installation at Urban Water Corporation in Bor town, South Sudan.",
            description: "2ML Consulting Limited sub-contracted Tracecorp Solutions to provide essential services for the Urban Water Corporation in Bor Town, South Sudan. These included gathering and re-engineering business processes for billing, accounting, asset management, and customer relationship management. Tracecorp designed and implemented a Billing System, a Customer Relationship Management (CRM) system, and a Financial Management Information System (FMIS) to manage financial transactions and enhance operational efficiency. They also developed an Asset Management System to optimize the utility’s resources. The project included user training, the creation of manuals, and ongoing support and maintenance to ensure smooth system operation.",
            imageSrc: "../../../images/bor1.jpg",
        },
    ];

    return (
        <div className="flex flex-col items-center bg-white px-4 sm:px-6 pt-36">
            <div className="flex flex-col items-center w-full mb-10">
                <h2 className="text-3xl sm:text-4xl font-bold text-gray-800">All Projects</h2>
                <p className="mt-2 text-base text-gray-500 text-center">
                    Explore all our featured projects
                </p>
            </div>

            <div className="flex flex-col gap-8 justify-start w-full sm:px-6 md:px-8 lg:px-16">
                {projects.map((project, index) => (
                    <div
                        key={index}
                        className="flex flex-col sm:flex-row w-full mb-10 items-center sm:gap-8 lg:gap-16" // Flex row on medium and up screens, flex column for small screens
                    >
                        {/* Image Section */}
                        <div className="relative w-full sm:w-1/3 h-auto mb-6 sm:mb-0">
                            <img
                                loading="lazy"
                                src={project.imageSrc}
                                alt={project.title}
                                className="object-cover w-full h-64 rounded-2xl shadow-md"
                            />
                        </div>

                        <div className="flex flex-col w-full sm:w-2/3">
                            <h3 className="text-xl font-bold leading-relaxed text-gray-800 sm:text-2xl">
                                {project.title}
                            </h3>
                            <div className="mt-4 leading-7 text-gray-500">
                                {project.description}
                            </div>
                            <Button
                                onClick={() => handleClick(index)}
                                className="px-4 mt-8 w-full text-base font-semibold leading-relaxed rounded-lg border-[1.5px] border-custom-blue min-h-[48px] text-custom-blue"
                            >
                                Read case study
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Back to Home Button */}
            <div className="mt-12 flex justify-center w-full">
                <Button
                    onClick={() => navigate("/")}
                    className="px-6 py-2 text-base font-semibold text-white border-none rounded-lg bg-custom-blue min-h-[40px]"
                >
                    Back to Home
                </Button>
            </div>
        </div>
    );
};

export default AllProjects;
